import React from "react"
import News from "../../../blocks/en/news_page"
import { Link } from "gatsby"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "maksimov_vs_fifa_in_cas" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>On 9 December 2019, Catenaccio's legal team represented a footballer <Link to={ `/en/players/maksimov` }>Maksim Maksimov</Link>, who pursues an appeal in the Court of Arbitration for Sport against FIFA on the basis that its Dispute Resolution Chamber wrongfully interpreted an employment contract in favour of his former employer. The dispute has attracted much publicity.</p>
        </div>
      </div>
    )
  }
}